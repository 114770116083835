



















































































































import useRole from "@/use/role";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    MEventsSelect: () => import("@/components/molecules/m-events-select.vue"),
  },

  setup(_, { root, emit }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      showPassword: false,
      events: [],
      groups: [],
      groupName: "",
      groupLoading: false,
      groupLoaded: false,
      changePassword: false,
    });

    const { userRole, getRoleName } = useRole({ root });
    const model = reactive<{
      data: any;
    }>({
      data: {
        phoneNumber: "",
        role: "",
        password: "",
        passwordRepeat: "",
        events: [],
      },
    });

    watch(
      () => model.data.group,
      () =>
        state.groups.filter(function(group: any) {
          if (group.id === model.data.group) {
            state.groupName = group.name;
          }
        })
    );

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("user/auth/group")
        .then(({ data: { groups } }) => {
          state.groups = groups.map((group: any, index: number) => ({
            id: index,
            name: "",
            ...group,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.groupLoaded = true;
          state.groupLoading = false;
        });
    };

    onMounted(fetchGroups);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`user/${root.$route.params.id}`)
        .then(({ data: { user } }) => {
          model.data.firstName = user.firstName || undefined;
          model.data.lastName = user.lastName || undefined;
          model.data.phoneNumber = user.phoneNumber || undefined;
          model.data.role = user.role || undefined;
          model.data.group = user.authGroup?.id || undefined;
          model.data.events =
            user.events.map((e: any) => ({
              id: e.id,
              name: e.name,
              siemensPrefix: e.siemensPrefix,
            })) || undefined;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, "any") ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          "Podaj poprawny kod pocztowy",
      ],
      passwordRepeat: [
        (v) => !!v || root.$tc("layout.misc.repeatPassword"),
        (v) => v === model.data.password || "Hasło musi być takie samo",
      ],
    };

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        firstName: model.data.firstName,
        lastName: model.data.lastName,
        phoneNumber: model.data.phoneNumber || null,
        role: model.data.role,
        authGroup: model.data.group || undefined,
        events: model.data.events.map((e: any) => e.id) || undefined,
        password: model.data.password || undefined,
      };

      state.loading = true;

      axiosInstance
        .put(`user/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 406:
          return "Nowe hasło nie może być takie samo jak poprzednie.";
        case 409:
          return "Użytkownik już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      userRole,
      getRoleName,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      rules,
    };
  },
});
